import React from "react"
import Layout from "../components/layout"
import PrivacySection from "../components/privacy_section"
export default function Privacy() {
  return (
    <Layout showButtons={false}>
      <section>
        <div className="max-w-screen-sm px-8 mx-auto pt-36 md:max-w-screen-md lg:max-w-screen-md">
          <h1 className="pb-2 text-xl text-center">
            POLITYKA PRYWATNOŚCI DANYCH OSOBOWYCH
          </h1>
          <h2 className="text-center">
            <strong>lappka.pl</strong>
          </h2>
          <p className="text-center">Obowiązuje od: 14.02.2021</p>

          <div className="py-12 space-y-12">
            <PrivacySection number="1" title="Tożsamość administratora danych">
              Administratorem danych osobowych podawanych w trakcie korzystania
              ze Serwisu online prowadzonego pod nazwą lappka.pl jest Paweł
              Ławiński, ul. Kamiennogórska 7/17, 60-179 Poznań,
              kontakt@lappka.pl, NIP: 4990602420, REGON: 363582647. Dane
              przetwarzane są zgodnie z aktualnie obowiązującymi przepisami
              prawa; tj. Rozporządzeniem Parlamentu Europejskiego i Rady UE
              2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
              związku z przetwarzaniem danych osobowych i w sprawie swobodnego
              przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej:
              RODO), ustawą z dnia 10 maja 2018 r. o ochronie danych, a także
              ustawą z 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.
            </PrivacySection>
            <PrivacySection number="2" title="Stosowane definicje">
              <ol className="list-decimal">
                <li>
                  W niniejszej polityce stosuje się następujące definicje:
                  <ol className="pl-10 list-alpha">
                    <li>
                      <strong>Serwis</strong> - serwis internetowy dostępny pod
                      adresem lappka.pl, za pośrednictwem którego Użytkownik
                      może: przeglądać jego zawartość, kontaktować się z
                      administratorem danych, zamówić informacje handlowe i
                      marketingowe.
                    </li>
                    <li>
                      <strong>Administrator danych osobowych</strong> - podmiot,
                      który decyduje o celu i środkach przetwarzania danych, w
                      niniejszej polityce rozumie się przez to: Paweł Ławiński,
                      ul. Kamiennogórska 7/17, 60-179 Poznań, NIP: 4990602420
                    </li>
                    <li>
                      <strong>Użytkownik</strong> - osoba fizyczna, której dane
                      dotyczą i która korzysta z usług dostępnych w Serwisie.
                    </li>
                    <li>
                      <strong>Dane osobowe</strong> - wszelkie informacje, które
                      bez nadmiernego czasu i kosztu mogą doprowadzić do
                      identyfikacji osoby fizycznej, w tym jej dane
                      identyfikacyjne, adresowe i kontaktowe.
                    </li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="3" title="Cele przetwarzania danych">
              <ol className="list-decimal">
                <li>
                  Administrator danych osobowych przetwarza dane osobowe w
                  następujących celach:
                  <ol className="pl-10 list-alpha">
                    <li>
                      udzielenia odpowiedzi na zadane pytanie poprzez formularz
                      kontaktowy lub za pośrednictwem danych teleadresowych
                      dostępnych na stronie Serwisu,
                    </li>
                    <li>
                      wysyłania informacji handlowych i marketingowych
                      administratora danych na adres e-mail podany przez
                      Użytkownika w trakcie zapisu na newsletter, za zgodą
                      osoby, której dane dotyczą,
                    </li>
                    <li>
                      dochodzenia praw i roszczeń przez administratora danych
                      lub osobę, której dane dotyczą.
                    </li>
                  </ol>
                </li>
                <li>
                  Podanie danych jest niezbędne dla realizacji zamówienia,
                  wystawienia dokumentu sprzedaży, dochodzenia roszczeń, a także
                  udzielenia odpowiedzi na pytania.
                </li>
                <li>Podanie pozostałych danych jest dobrowolne.</li>
                <li>
                  Brak podania wymaganych danych uniemożliwia realizację usług,
                  w tym zamówienia i kontaktu.
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="4" title="Sposoby pozyskiwania danych">
              <ol className="list-decimal">
                <li>
                  Dane osobowe Użytkownika gromadzone są bezpośrednio od osób,
                  których dane dotyczą, tj. poprzez:
                  <ol className="pl-10 list-alpha">
                    <li>
                      wypełnienie formularza z danymi kontaktowymi podczas
                      składania zapytania przez formularz na stronie,
                    </li>
                    <li>wypełnienie formularza zapisu na newsletter,</li>
                    <li>
                      bezpośredni kontakt z administratorem danych za pomocą
                      danych teleadresowych dostępnych na stronie lub w formie
                      tradycyjnej w miejscu prowadzenia działalności.
                    </li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="5" title="Zakres przetwarzanych danych">
              <ol className="list-decimal">
                <li>
                  Zakres przetwarzanych danych osobowych został ograniczony do
                  minimum niezbędnego do świadczenia usług w zakresie:
                  <ol className="pl-10 list-alpha">
                    <li>
                      złożenia zapytania przez formularz kontaktowy lub za
                      pomocą danych teleadresowych dostępnych na stronie: adres
                      e-mail, ewentualne inne dane podane dobrowolnie przez
                      osobę, której dane dotyczą,
                    </li>
                    <li>dokonania zapisu na newsletter: adres e-mail</li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="6" title="Okres przetwarzania danych">
              <ol className="list-decimal">
                <li>
                  Dane osobowe są przetwarzane przez okres niezbędny do
                  realizacji celu, dla którego zostały zebrane, tj.:
                  <ol className="pl-10 list-alpha">
                    <li>
                      przez okres niezbędny do udzielenia odpowiedzi na pytanie
                      zadane przez formularz kontaktowy lub drogą telefoniczną,
                    </li>
                    <li>
                      do czasu odwołania zgody, jeśli przetwarzanie danych
                      opiera się na zgodzie osoby, której dane dotyczą.
                    </li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="7" title="Odbiorcy danych">
              <ol className="list-decimal">
                <li>
                  Dane osobowe Użytkownika mogą zostać powierzone innym
                  podmiotom w celu wykonania usług na zlecenie administratora
                  danych, w szczególności podmiotom w zakresie:
                  <ol className="pl-10 list-alpha">
                    <li>hostingu strony www,</li>
                    <li>
                      serwisu i utrzymania systemów informatycznych, w których
                      dane są przetwarzane, w tym w celu automatyzacji
                      newslettera, etc.
                    </li>
                  </ol>
                </li>
                <li>
                  Dane osobowe Użytkownika mogą być przekazywane do państw
                  trzecich i organizacji międzynarodowych w związku z.
                  <ol className="pl-10 list-alpha">
                    <li>
                      prowadzeniem przez nas korespondencji elektronicznej oraz
                      analizą statystyk stron internetowych – odbiorcą danych
                      może być w tym wypadku spółka Google LLC z siedzibą w USA
                      oraz podmioty zależne i podwykonawcy spółki Google LLC
                      mające siedzibę w USA, albowiem naszym partnerem jest
                      spółka Google Ireland Ltd powiązana z w/w podmiotami;
                    </li>
                    <li>
                      wysyłką newslettera – odbiorcą danych jest spółka The
                      Rocket Science Group LLC z siedzibą w USA (właściciel
                      systemu mailingowego MailChimp);
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                Podstawą prawną transferu Twoich danych do spółki Google LLC i
                jej spółek zależnych oraz mechanizmem prawnym zabezpieczającym
                ten transfer są Standardowe Klauzule Umowne przyjęte przez
                Komisję Europejską. Podstawą prawną transferu Twoich danych do
                wyżej wskazanych odbiorców danych oraz mechanizmem prawnym
                zabezpieczającym ten transfer są Standardowe Klauzule Umowne
                przyjęte przez Komisję Europejską. Więcej informacji na temat
                zasad przetwarzania danych osobowych przez spółki Google Ireland
                Ltd. oraz Google LLC znaleźć można pod poniższym adresem:
                https://policies.google.com/privacy.
              </p>
            </PrivacySection>
            <PrivacySection number="8" title="Prawa osób, których dane dotyczą">
              <ol className="list-decimal">
                <li>
                  Osobie, której dane dotyczą przysługuje:
                  <ol className="pl-10 list-alpha">
                    <li>prawo dostępu do treści danych i ich sprostowania,</li>
                    <li>
                      prawo do usunięcia danych, o ile nie obowiązują inne
                      przepisy prawa, które zobowiązują administratora danych do
                      archiwizacji danych przez określony czas,
                    </li>
                    <li>
                      prawo do przenoszenia danych, o ile podstawą przetwarzania
                      danych jest umowa lub zgoda osoby, której dane dotyczą, a
                      przetwarzanie danych odbywa się w sposób automatyczny,
                    </li>
                    <li>
                      prawo do wniesienia sprzeciwu na przetwarzanie danych w
                      celach marketingu bezpośredniego, realizowanego przez
                      administratora danych w ramach usprawiedliwionego interesu
                      prawnego, a także ograniczenia przetwarzania,
                    </li>
                    <li>
                      prawo do niepodlegania automatycznemu profilowaniu, jeśli
                      administrator danych podejmowałby decyzje opierające się
                      wyłącznie na automatycznym profilowaniu i niosące ze sobą
                      skutki prawne dla osoby, której dane dotyczą lub podobnie
                      na nią wpływały,
                    </li>
                    <li>
                      prawo do kontroli przetwarzania danych i informacji na
                      temat tego, kto jest administratorem danych, a także
                      uzyskania informacji o celu, zakresie i sposobie
                      przetwarzania danych, treści tych danych, źródle danych, a
                      także sposobie udostępniania, w tym o odbiorcach lub
                      kategoriach odbiorców danych,
                    </li>
                    <li>
                      prawo do cofnięcia zgody w dowolnym momencie, jeśli
                      podstawą przetwarzania danych była zgoda osoby, której
                      dane dotyczą. Odwołanie zgody nie wpływa na zgodność z
                      prawem przetwarzania dokonanego na jej podstawie, przed
                      jej cofnięciem,
                    </li>
                    <li>
                      prawo do wniesienia skargi do Prezesa Urzędu Ochrony
                      Danych Osobowych (PUODO), jeśli osoba uzna, że
                      przetwarzanie jej danych jest niezgodne z aktualnie
                      obowiązującymi przepisami w tym zakresie.
                    </li>
                  </ol>
                </li>
                <li>
                  W celu realizacji prawa do kontroli danych, dostępu do treści
                  danych, ich poprawiania, a także innych praw, należy
                  skontaktować się z administratorem danych.
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="9" title="Postanowienia końcowe">
              <ol className="list-decimal">
                <li>
                  Administrator danych zastrzega możliwość zmiany niniejszej
                  polityki prywatności, szczególnie w sytuacji, gdy będą tego
                  wymagały zastosowane rozwiązania techniczne, zmiany przepisów
                  prawa w zakresie prywatności osób, których dane dotyczą lub
                  inne, istotne zmiany wpływające na treść informacji
                  przekazywanej Użytkownikom.
                </li>
                <li>
                  W przypadku zmiany obowiązującej polityki prywatności,
                  wprowadzone zostaną odpowiednie modyfikacje do powyższego
                  zapisu, które będą obowiązywały od dnia ich opublikowania na
                  stronie Serwisu.
                </li>
              </ol>
            </PrivacySection>
          </div>
        </div>
      </section>
    </Layout>
  )
}
