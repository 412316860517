import React from "react"

export default function PrivacySection(props) {
  return (
    <div className="flex-column">
      <p className="text-center">
        <strong>§{props.number}</strong>
      </p>
      <p className="text-center">
        <strong>{props.title}</strong>
      </p>
      <p className="pt-6">{props.children}</p>
    </div>
  )
}
